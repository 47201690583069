import request from '@/utils/request'



export function getAfficheData(params) {
    return request({
        url: '/lowcode/announcement/page',
        method: 'get',
        params
    })
}
export function getannouncementDetail(params) {
    return request({
        url: '/lowcode/announcement/announcementDetail',
        method: 'get',
        params
    })
}
export function getannouncementview(params) {
    return request({
        url: '/lowcode/announcement/addAnnouncementRead',
        method: 'get',
        params
    })
}
export function courseSignin(params) {
    return request({
        url: `/lowcode/trainingCourseReservation/signIn/${params.id}`,
        method: 'get',
        params
    })
}
export function activitySignin(params) {
    return request({
        url: `/lowcode/bizActivityReserve/signIn/${params.id}`,
        method: 'get',
        params
    })
}

export function getFixedTopArticleTypeList(params) {
    return request({
        url: `/lowcode/articleType/getFixedTopArticleTypeList`,
        method: 'get',
        params
    })
}
export function getArticleTypePage(params) {
    return request({
        url: `/lowcode/articleType/getArticleTypePage`,
        method: 'get',
        params
    })
}
export function getCourseSigninState (params) {  
    return request({
        url: `/lowcode/trainingCourseReservation/signInStatus/${params.id}`,
        method: 'get',
        params
    })
}

export function getActivitySigninState (params) {  
    return request({
        url: `/lowcode/bizActivityReserve/signInStatus/${params.id}`,
        method: 'get',
        params
    })
}